




















































import { Component, Vue } from "vue-property-decorator";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { Type } from "@/lib/utility/type";
import { ReportModule } from "@/store/modules/report.store";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { ConfigModule } from "@/store/modules/config";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerModule } from "@/store/modules/partner";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ICreateReport } from "@/store/models/mrfiktiv/report";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportSummary extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.summary.title",
    description: this.description
  };

  screen = ReportScreenEnum.closing;

  // TODO: Fix this as it is not reactive.
  get description() {
    return String(
      this.$t("report.summary.description", {
        privacy:
          "<a target='blank()' href='https://www.mmmint.ai/datenschutz/'>" +
          this.$t("report.summary.privacyPolicy") +
          "</a>."
      })
    );
  }

  get privacyPolicyLink() {
    return ConfigModule.privacyPolicyLink;
  }

  isLoading = false;
  valid = false;
  isReviewed = false;

  get email() {
    return ReportModule.contact.email;
  }

  get isDone() {
    return this.valid && this.isReviewed && this.isPrivacyChecked;
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  get reportReviewedRules() {
    const rules = [];

    rules.push(requiredRule());

    //TODO: Make this ways smarter

    if (ReportModule.screenOder.includes(ReportScreenEnum.contactdetails)) {
      rules.push(this.reportAddressValidRule());
      rules.push(this.reportContactEmailValidRule());
      rules.push(this.reportContactPhoneValidRule());
      rules.push(this.reportUserValidRule());
      rules.push(this.reportNumberValidRule());
    }

    if (
      ReportModule.screenOder.includes(ReportScreenEnum.damagelocator) ||
      ReportModule.screenOder.includes(ReportScreenEnum.damagelocatortruck) ||
      ReportModule.screenOder.includes(ReportScreenEnum.damagelocatorcombined)
    ) {
      rules.push(this.reportDamageLocationValidRule());
    }

    if (ReportModule.screenOder.includes(ReportScreenEnum.simplecontact)) {
      rules.push(this.reportUserValidRule());
      rules.push(this.reportContactEmailValidRule());
      rules.push(this.reportNumberValidRule());
    }

    return rules;
  }

  reportAddressValidRule(message = "report.summary.addressMandatory"): boolean | string {
    const streetValid = Type.isPresent(ReportModule.address.street) && !Type.isBlankString(ReportModule.address.street);
    const zipValid = Type.isPresent(ReportModule.address.zip) && !Type.isBlankString(ReportModule.address.zip);
    const cityValid = Type.isPresent(ReportModule.address.city) && !Type.isBlankString(ReportModule.address.city);
    return (streetValid && zipValid && cityValid) || String(this.$t(message));
  }

  reportContactPhoneValidRule(message = "report.summary.phoneMandatory"): boolean | string {
    return (
      (Type.isPresent(ReportModule.contact.phone) && !Type.isBlankString(ReportModule.contact.phone)) ||
      String(this.$t(message))
    );
  }

  reportContactEmailValidRule(message = "report.summary.mailMandatory"): boolean | string {
    return (
      (Type.isPresent(ReportModule.contact.email) && !Type.isBlankString(ReportModule.contact.email)) ||
      String(this.$t(message))
    );
  }

  reportUserValidRule(message = "report.summary.nameMandatory"): boolean | string {
    return (
      (Type.isPresent(ReportModule.user.firstName) &&
        Type.isPresent(ReportModule.user.lastName) &&
        !Type.isBlankString(ReportModule.user.firstName) &&
        !Type.isBlankString(ReportModule.user.lastName)) ||
      String(this.$t(message))
    );
  }

  reportDamageLocationValidRule(message = "report.summary.damageLocatorMandatory"): boolean | string {
    return ReportModule.inCreationReport.damageLocation.length > 0 || String(this.$t(message));
  }

  reportDamageDescriptionValidRule(message = "report.summary.damageDescriptionMandatory"): boolean | string {
    return (
      (Type.isPresent(ReportModule.inCreationReport.message) &&
        !Type.isBlankString(ReportModule.inCreationReport.message)) ||
      String(this.$t(message))
    );
  }

  reportNumberValidRule(message = "report.summary.numberplateMandatory"): boolean | string {
    return (
      (Type.isPresent(ReportModule.numberplate) && !Type.isBlankString(ReportModule.numberplate)) ||
      String(this.$t(message))
    );
  }

  get isPrivacyChecked(): boolean {
    return ReportModule.inCreationReport.isPrivacyChecked;
  }

  set isPrivacyChecked(bool: boolean) {
    ReportModule.setPrivacyCheck(bool);
  }

  get isMarketingContactAllowed(): boolean {
    return ReportModule.inCreationReport.isMarketingOptIn;
  }

  set isMarketingContactAllowed(bool: boolean) {
    ReportModule.setMarketingContactAllowed(bool);
  }

  get report(): ICreateReport {
    return ReportModule.inCreationReport;
  }

  async submit() {
    if (this.form.validate()) {
      this.isLoading = true;
      try {
        await ReportModule.submit();
        PartnerModule.setRatingSubmissionEnabled(true);
        this.$router.push({ name: "ReportSuccess" });
      } catch (error) {
        this.$log.error(error);
        this.$toast.error("Fehler beim senden.");
      } finally {
        this.isLoading = false;
      }
    }
  }
}
