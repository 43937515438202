var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ma-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [!_vm.loading ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.data, function (d, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "6",
        "sm": 12 / _vm.data.length
      }
    }, [d.display !== false ? _c('statistics-card-column', {
      attrs: {
        "icon": d.icon,
        "color": d.color,
        "title": d.title,
        "data": d.data,
        "tooltip": d.tooltip,
        "click": d.click
      }
    }) : _vm._e()], 1);
  }), 1) : _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }